import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/Seo";
import Breadcrumbs from "components/Breadcrumbs";
import SectionTitle from "components/SectionTitle";
import VotesMap from "components/VotesMap";

const mapPage = ({ pageContext, data }) => {
   const mapData = data.allWpVote.nodes;
   const seo = pageContext.seo;

   return (
      <Layout>
         <Seo title={seo.title ? seo.title : pageContext.title} />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <div className="container">
            <SectionTitle title={pageContext.title} />
            <VotesMap data={mapData} />
         </div>
      </Layout>
   );
};

export const query = graphql`
   query {
      allWpVote {
         nodes {
            singleVotePage {
               mapLat
               mapLong
               mapRealisation
               mapSubregion
               shortDesc
               isShownOnMap
            }
            title
            uri
         }
      }
   }
`;

export default mapPage;
